import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import React, { useState, useEffect} from 'react';
import Spielplan from './img/Spielplan.pdf';

const League = () => {
    return (
        <section id="liga" className="ligaSection">
            <Container style={{maxWidth: 850 + "px"}}>
                <Row className="ligaRow">
                <h2 style={{textAlign: "center"}}>Wichtiges zum Ligabetrieb</h2>
                    <Col>
                        <p>Der DFGP nimmt aktuell mit 3 Mannschaften am Ligabetrieb teil:<br></br>
                        - Dartfreunde Geisling-Pfatter (Bezirksklasse I)<br></br>
                        - Dartfreunde Geisling-Pfatter II (Kreisliga I)<br></br>
                        - Dartfreunde Geisling-Pfatter III (Kreisliga VI)
                        </p>
                        <p>Hier kommen Sie zu unseren Mannschaften, Ligeneinteilungen und den aktuellen <a href="https://bdv-dart.liga.nu/cgi-bin/WebObjects/nuLigaDARTDE.woa/wa/clubTeams?club=2081" target="_blank" rel="noreferrer">Tabellen</a>.</p>
                        <p>Downloads: <a href={Spielplan}  target="_blank" rel="noreferrer">Aktueller Spielplan aller 3 Mannschaften</a></p>
                    </Col>
                    <Col>
                        <Counter />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}


const Counter = () => {
    const label = "Gründungsmitglieder";
    const number = 0;
    const duration = 4;
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        let start = 0;
        const end = 13;
        
            if (start === end){
                return;
            } 
     
            let totalMilSecDur = duration;
            let incrementTime = (totalMilSecDur / end) * 1000;
            
            let timer = setInterval(() => {
                start += 1;
                setCount(start + number);
                if(start === end) {
                    clearInterval(timer);
                    return;
                }
            }, incrementTime)
        
    }, [number, duration]);
    
    return (
        <div className="Count">
          <h3>
            <i>{label}: {count}</i>
          </h3>
        </div>
      )
}


export default League;
